/* Copyright 2022- Martin Kufner */
Object.defineProperties(Node.prototype, {
    getElementByItemid: {
        value: function (itemid) { return this.querySelector(`[itemid="${itemid}"]`) }
    },
    getElementsByItemid: {
        value: function (itemid) { return [...this.querySelectorAll(`[itemid="${itemid}"]`)] }
    },
    itempropsUncached: {
        get: function () {
            const itemid = this.itemid;
            if(!itemid) return [];
            const exclude_all_with_itemid_between = `[itemid="${itemid}"] [itemid] [itemprop]`;
            const nodes = [...this.querySelectorAll('[itemprop]')].filter(n => !n.matches(exclude_all_with_itemid_between));
            const value = Object.fromEntries(nodes.map(n => [n.itemprop, n]));
            Object.defineProperty(this, 'itemprops', {value})
            return value;
        }
    },
    itemprops: {
        get: function () { return itempropsUncached }
    },
    ancestors: {
        value(test) {
            let t;
            if(test instanceof Node) t = ({e}) => e === test;
            else if(typeof test === "function") t = test;
            else if(typeof test === "string") t = ({e}) => {
                if(!e) return true;
                if(e.matches) return e.matches(test);
            }
            const fn = function* (e) {
                let l, n;
                do {
                    n = e.parentNode || e.host;
                    yield e;
                    if(t && t({e, l, n})) break;
                    l = e;
                    e = n;
                } while(e);
            }
            return [...fn(this)];
        }
    },
    closestNode: {
        value(test) {
            const rv = this.ancestors(test);
            return rv[rv.length - 1];
        }
    },
    childSelector: {
        value(selector) {
            return [...this.children].find(n => n.matches(selector)) || null;
        }
    },
    childSelectorAll: {
        value(selector) {
            return [...this.children].filter(n => n.matches(selector));
        }
    },
    getTextNodes: {
        get() {
            const nodes = [],
                iterator = document.createNodeIterator(this, NodeFilter.SHOW_TEXT);
            let currentNode;
            while((currentNode = iterator.nextNode())) nodes.push(currentNode);
            return nodes;
        }
    },
    scrollIntoViewWithMargin: {
        value(option) {
            const {margin = 10} = option || {};
            const {bottom, top} = this.getBoundingClientRect();
            console.log(rect);
            const pageTop = window.scrollY,
                pageBottom = pageTop + window.innerHeight;
            if(bottom + margin > pageBottom) window.scrollTo({top: pageBottom + bottom + margin + pageTop});
            // const offsetTop = window.scrollY + rect.top - option.margin;
            //
            // window.scrollTo({
            //     top: offsetTop,
            //     behavior: 'smooth'
            // });
        }
    },

    querySelectorDeep: {
        value(selector) {
            const nodes = [...this.querySelectorAll(selector)];
            const shadow = node => [...node.getElementsByTagName('*')].filter(n => n.contentRoot).flatMap(n => {
                const nodes = [...n.contentRoot.querySelectorAll(selector)];
                nodes.push(...shadow(n));
                return nodes;
            });
            nodes.push(...shadow(this));
            return nodes;
        }
    },
    hostNode: {
        get () { return this.getRootNode().host || self }
    }

    // getElementsByNodeNameStarting: {
    //     value(name) {
    //         const nodes = [],
    //             iterator = document.createNodeIterator(this, NodeFilter.SHOW_ELEMENT,
    //                 (node) =>
    //                     node.nodeName.startsWith(name)
    //                         ? NodeFilter.FILTER_ACCEPT
    //                         : NodeFilter.FILTER_REJECT
    //             );
    //         let currentNode;
    //         while((currentNode = iterator.nextNode())) nodes.push(currentNode);
    //         return nodes;
    //     }
    // }
});

