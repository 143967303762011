/* Copyright 2022- Martin Kufner */

const LANGUAGE_COUNTRY = {
    en: 'gb'
}

const confirm = self.confirm;
Object.defineProperties(window, {
    getFlagEmoji: {
        value: function (countryCode, replacement) {
            if (!/^[a-z]{2}/.test(countryCode)) return replacement || "";
            if(countryCode in LANGUAGE_COUNTRY) countryCode = LANGUAGE_COUNTRY[countryCode];
            const codePoints = countryCode
                .toUpperCase()
                .split('')
                .map(char => 127397 + char.charCodeAt());
            return String.fromCodePoint(...codePoints) || replacement || '';
        }
    },
    uuid: {
        get() {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        }
    },
    uuid_pack: {
      value(v) {
          const [a,b,c,d,e] = v.split("-");
          return Number.pack(parseInt(a + b + c, 16)) + Number.pack(parseInt(d + e, 16));
      }
    },
    UUID_RE: {
        value: /([0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12})/
    },
    UUID_REG: {
        value: /([0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12})/g
    },
    randomId: {
        value(pos=6) {
            return (Math.floor(Math.random() * 26 + 10).toString(36) + Math.floor(Math.random() * 36 ** pos).toString(36)).substr(0, pos);
        }
    },

    notification: {
       value: {
           info(...text) { console.info(...text) },
           log(...text) { console.log(...text) },
           warn(...text) { console.warn(...text) },
           error(...text) { console.error(...text) }
       }
    },
    pageTop: {
        get() { return window.scrollY; }
    },
    pageBottom: {
        get() { return window.scrollY  + window.innerHeight; }
    },
    confirmPromise: {
        value(text) {
            if(confirm(text)) return Promise.resolve();
            else return Promise.reject();
        }
    }

});

